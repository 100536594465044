import React, { memo } from 'react';
import styled from '@emotion/styled';
import dynamic from 'next/dynamic';
import Logo from './Logo';

const HeaderLogin = dynamic(import('./HeaderLogin'), {
  ssr: false,
  loading: () => null
});

const StyledHeader = styled.header`
  align-items: center;
  background: ${({ theme }) => theme.palette.BEIGE};
  display: flex;
  height: ${({ theme }) => theme.headerHeight};
  justify-content: space-between;
  left: 0;
  padding: ${({ theme }) => `0 ${theme.space.xlarge}`};
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
`;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
`;

const Header = ({ minimalHeader }) => {
  return (
    <StyledHeader>
      <Logo large black />
      {!minimalHeader && (
        <Wrapper>
          <HeaderLogin />
        </Wrapper>
      )}
    </StyledHeader>
  );
};

export default memo(Header);
