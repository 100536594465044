import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  createUser,
  deleteUser as deleteUserApi,
  getUserByFirebaseId
} from 'api';
import { useNotificationContext } from 'contexts/NotificationContext';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  onAuthStateChanged,
  fetchSignInMethodsForEmail,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  EmailAuthProvider
} from 'firebase/auth';
import { useRouter } from 'next/router';
import { setCookie } from 'utils/cookieManagement';

const THREE_MINUTES = 1000 * 60 * 3;

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_firebase_public_api_key,
  authDomain: process.env.NEXT_PUBLIC_firebase_auth_domain,
  databaseURL: process.env.NEXT_PUBLIC_firebase_database_url,
  projectId: process.env.NEXT_PUBLIC_firebase_project_id,
  messagingSenderId: process.env.NEXT_PUBLIC_firebase_messaging_sender_id,
  appId: process.env.NEXT_PUBLIC_firebase_app_id
};

initializeApp(firebaseConfig);

const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { push, query } = useRouter();
  const { setNotification } = useNotificationContext();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (fbUser) => {
      if (fbUser) {
        const date = new Date();
        const userCreationDate = new Date(fbUser.metadata.creationTime);
        if (date - userCreationDate < THREE_MINUTES) return;

        const user = await getUserByFirebaseId(fbUser.uid);
        setCookie('user', user);
        setUser(user);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  const emailExist = async (email) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const resp = await fetchSignInMethodsForEmail(auth, email);
      if (resp.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1) {
        return true;
      }
      return false;
    } catch (e) {
      console.log('err... emailExist', e);
    } finally {
      setLoading(false);
    }
  };

  const login = async (email, password, onError) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const credentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = await getUserByFirebaseId(credentials.user.uid);
      setUser(user);
      push('/profile');
    } catch (e) {
      if (e?.code === 'auth/wrong-password') {
        setNotification({
          message: 'Lösenordet du angett är felaktiga. försök igen',
          severity: 'error'
        });
      } else {
        setNotification({
          message: 'Något gick fel. Prova igen senare eller kontakta support!',
          severity: 'error'
        });
      }
      if (onError) onError();
    } finally {
      setLoading(false);
    }
  };

  const signup = async (email, password) => {
    try {
      setLoading(true);
      const auth = getAuth();
      const credentials = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const fbUser = credentials.user;
      const payload = {
        accountlvl: 1,
        email: fbUser.email,
        fb_id: fbUser.uid,
        slug: fbUser.email.split('@')[0]
      };
      const user = await createUser({ data: payload });
      setUser(user);
      if (query?.premium) return push('/profile?tab=1');
      push('/profile');
    } catch (e) {
      setNotification({
        message: 'Något gick fel. Prova igen senare eller kontakta support!',
        severity: 'error'
      });
      console.log('err... signup', e);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    const auth = getAuth();
    await signOut(auth);
    push('/');
    setUser(null);
  };

  const resetPassword = async (email) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
    } catch (e) {
      setNotification({
        message: 'Något gick fel. Prova igen senare eller kontakta support!',
        severity: 'error'
      });
      console.log('err... resetpw', e);
    }
  };

  const deleteUser = async () => {
    try {
      const { slug, fb_id } = user;
      await deleteUserApi({
        data: { slug, fb_id }
      });
      push('/');
      setUser(null);
    } catch (e) {
      setNotification({
        message: 'Något gick fel. Prova igen senare eller kontakta support!',
        severity: 'error'
      });
    }
  };

  const updateUser = (newUserValues) => {
    setUser({ ...user, ...newUserValues });
  };

  const values = { user, loading };
  const methods = {
    emailExist,
    login,
    signup,
    logout,
    resetPassword,
    deleteUser,
    updateUser
  };

  return (
    <AuthContext.Provider value={{ ...values, ...methods }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthContextProvider, useAuthContext };
export default AuthContext;
