import Cookies from 'js-cookie';

const getCookie = (key) => {
  try {
    const cookieData = Cookies.get(key);
    return cookieData ? JSON.parse(cookieData) : '';
  } catch (error) {
    console.error(`Couldn't fetch ${key} cookie! `, error);
  }
};

const setCookie = (key, obj, expire = 1) => {
  try {
    Cookies.set(key, JSON.stringify(obj), { expires: expire });
  } catch (error) {
    console.error(`Couldn't set ${key} cookie! `, error);
  }
};

export { getCookie, setCookie };
