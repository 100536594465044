import React from 'react';
import styled from '@emotion/styled';
import Footer from 'components/Footer';
import Header from 'components/header';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.palette.WHITE};
  margin: 0;
  min-height: ${({ fullHeight }) => fullHeight && '100vh'};
  padding: 0;
  padding-top: ${({ toppPadding }) => toppPadding || '60px'};
  padding-bottom: ${({ theme, noBottomSpacing }) =>
    !noBottomSpacing && theme.sectionDivider.medium};
`;

const Main = styled.main``;

const Layout = ({
  noLayout = false,
  backgroundColor,
  noBottomSpacing = false,
  children,
  fullHeight = true,
  header = true,
  footer = false,
  minimalHeader = false,
  toppPadding
}) => {
  if (noLayout) return <Main>{children}</Main>;
  return (
    <Wrapper
      backgroundColor={backgroundColor}
      fullHeight={fullHeight}
      header={header}
      toppPadding={toppPadding}
      noBottomSpacing={noBottomSpacing}
    >
      {header && <Header minimalHeader={minimalHeader} />}
      <Main>{children}</Main>
      {footer && <Footer />}
    </Wrapper>
  );
};

export default Layout;
