import styled from '@emotion/styled';

const StyledH3 = styled.h3`
  margin-top: 8px;
  margin-bottom: 6px;
`;

const H3 = ({ children, ...rest }) => <StyledH3 {...rest}>{children}</StyledH3>;

export default H3;
