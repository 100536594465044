import { createTheme } from '@material-ui/core/styles';
import theme from './theme';

const muiTheme = createTheme({
  overrides: {
    MuiSnackbar: {
      root: { top: '75px !important' }
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-around'
      }
    },
    MuiTab: {
      root: {
        padding: 6,
        fontSize: 12,
        minWidth: 0,
        '@media (min-width: 600px)': {
          minWidth: 0,
          padding: 8,
          fontSize: 14
        }
      }
    },
    MuiButton: {
      contained: {
        padding: '12px 18px',
        '&:active': { backgroundColor: theme.palette.GREEN },
        '&:hover': { backgroundColor: `${theme.palette.GREEN} !important` },
        backgroundColor: theme.palette.GREEN,
        color: theme.palette.WHITE,
        boxShadow: ''
      },
      containedPrimary: {
        '&:active': { backgroundColor: theme.palette.BEIGE },
        '&:hover': {
          backgroundColor: `${theme.palette.BEIGE_DARK} !important`
        },
        backgroundColor: theme.palette.BEIGE,
        color: theme.palette.WHITE
      },
      containedSecondary: {
        '&:active': { backgroundColor: theme.palette.RED },
        '&:hover': { backgroundColor: `${theme.palette.RED_DARK} !important` },
        backgroundColor: theme.palette.RED,
        color: theme.palette.WHITE
      },
      outlined: {
        padding: '12px 18px',
        '&:active': {
          borderColor: theme.palette.GREEN
        },
        '&:hover': {
          borderColor: theme.palette.GREEN_DARK,
          color: theme.palette.GREEN_DARK
        },
        color: theme.palette.GREEN,
        borderColor: theme.palette.GREEN
      },
      outlinedPrimary: {
        '&:active': {
          borderColor: theme.palette.BEIGE_DARK,
          color: theme.palette.BEIGE_DARK
        },
        '&:hover': {
          borderColor: theme.palette.BEIGE_DARK,
          color: theme.palette.BEIGE_DARK
        },
        borderColor: theme.palette.BEIGE,
        color: theme.palette.BEIGE
      },
      root: {
        borderRadius: '24px',
        fontSize: '1rem',
        textTransform: 'none'
      }
    },
    MuiFilledInput: {
      root: {
        borderRadius: '12px',
        borderTopLeftRadius: '12px',
        borderTopRightRadius: '12px'
      },
      underline: {
        borderBottom: 0,
        '&:before': {
          borderBottom: 0
        },
        '&:after': {
          borderBottom: 0
        },
        '&:hover:before': {
          borderBottom: 0
        }
      }
    },
    MuiInputLabel: {
      root: {
        '&$focused': {
          color: 'black'
        }
      }
    }
  }
});

export default muiTheme;
