import styled from '@emotion/styled';

const StyledH2 = styled.h2`
  margin-top: 12px;
  margin-bottom: 6px;
`;

const H2 = ({ children, ...rest }) => <StyledH2 {...rest}>{children}</StyledH2>;

export default H2;
