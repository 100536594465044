import styled from '@emotion/styled';

const StyledH1 = styled.h1`
  margin-top: 12px;
  margin-bottom: 8px;
`;

const H1 = ({ children, ...rest }) => <StyledH1 {...rest}>{children}</StyledH1>;

export default H1;
