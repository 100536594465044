const theme = {
  borderRadius: '6px',
  borderRadiusM: '16px',
  borderRadiusXL: '30px',
  boxShadow: '5px 5px 10px 2px',
  boxShadowSmall: '1px 1px 4px 1px',
  boxShadowMedium: '2px 2px 4px 1px',
  boxShadowButton: '0px 2px 4px 1px rgba(0, 0, 0, 0.15)',
  headerHeight: '65px',
  headerItemHeight: '28px',
  iconHeight: { xsmall: '14px', small: '20px', medium: '30px', large: '35px' },
  palette: {
    BEIGE: '#e9d3b8',
    BEIGE_DARK: '#c1af99',
    BLACK: '#000000',
    BLUE: '#1d99c7',
    DARK_GRAY: '#7b7b7b',
    DARK_BLUE: '#10789e',
    GRAY: '#d4d4d4',
    GREEN: '#3a9280',
    GREEN_DARK: '#2e7365',
    LIGHT_GRAY: '#f4f4f4',
    PURPLE: '#a4828a',
    RED: '#ec6971',
    RED_DARK: '#bd545a',
    WHITE: '#ffffff',
    YELLOW: '#fdd231'
  },
  sectionDivider: {
    xsmall: '2rem',
    small: '2.5rem',
    medium: '4.5rem',
    large: '6.5rem'
  },
  space: {
    xxsmall: '0.25rem', // 4px
    xsmall: '0.5rem', // 8px
    small: '0.75rem', // 12px
    medium: '1rem', // 16px
    large: '1.25rem', // 20px
    xlarge: '1.5rem', // 24px
    xxlarge: '2rem', // 32px
    xxxlarge: '2.5rem', // 40px
    xxxxlarge: '3rem' // 48px
  },
  imgSize: {
    small: '45px',
    small: '80px',
    medium: '125px'
  },
  width: {
    xxxxsmall: '100px',
    xxxsmall: '200px',
    xxsmall: '300px',
    xsmall: '400px',
    small: '600px',
    medium: '800px',
    large: '1000px'
  },
  breakPoints: {
    mobileS: '600px',
    mobile: '765px',
    tablet: '900px',
    desktop: '1280',
    largeDesktop: '1690px'
  }
};

export default theme;
