import * as Sentry from '@sentry/nextjs';
import * as _ from 'lodash';
import { getCookie } from 'utils/cookieManagement';

export const sentryAPICatch = (error, url) => {
  const user = getCookie('user');
  Sentry.configureScope((scope) =>
    scope
      .setLevel('Error')
      .setUser({ email: _.get(user, 'email', 'undefined') })
      .setExtra('Name', _.get(user, 'firstName', 'undefined'))
      .setExtra('Fb_id', _.get(user, 'fb_id', 'undefined'))
      .setExtra('slug', _.get(user, 'slug', 'undefined'))
      .setExtra('Account_Lvl', _.get(user, 'accountlvl', 'undefined'))
      .setExtra('Companies_Access', _.get(user, 'companiesAccess', 'undefined'))
      .setExtra('Endpoint', url)
  );
  Sentry.showReportDialog(); //- If you want get users feedback on error (Displays dialog)
  return Sentry.captureException(error);
};
