import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Link from 'components/Link';
import { P } from 'components/typography';
import config from 'config';
import FacebookSvg from '../assets/icons/facebook.svg';
import InstagramSvg from '../assets/icons/instagram.svg';
import LinkedInSvg from '../assets/icons/linkedin.svg';

const Wrapper = styled.footer`
  margin-top: auto;
  padding: ${({ theme }) => `${theme.space.large} ${theme.space.xsmall}`};
  display: flex;
  justify-content: space-around;
  border-top: 2px solid black;
  background: ${({ theme }) => theme.palette.BEIGE};

  @media (max-width: ${({ theme }) => theme.breakPoints.mobile}) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  margin: ${({ theme }) => theme.space.xsmall};
  margin-top: ${({ theme }) => theme.space.xxlarge};
  display: flex;
  flex-direction: column;
`;

const ColumnTitle = styled(P)`
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
`;

const Logo = styled(P)`
  font-family: 'Rajdhani', sans-serif;
  color: ${({ theme }) => theme.palette.BLACK};
  letter-spacing: 2.5px;
  font-weight: bold;
  font-size: 40px;
  margin: 0;
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: underline;
  padding-bottom: ${({ theme }) => theme.space.xsmall};
`;

const IconWrapper = styled.div`
  display: flex;
`;

const iconStyle = ({ theme }) => css`
  height: ${theme.iconHeight.medium};
  width: auto;
`;

const LinkedInIcon = styled(LinkedInSvg)`
  ${iconStyle}
  padding-right: ${({ theme }) => theme.space.xsmall};
`;

const InstagramIcon = styled(InstagramSvg)`
  ${iconStyle}
  padding-right:  ${({ theme }) => theme.space.xsmall};
`;

const FacebookIcon = styled(FacebookSvg)`
  ${iconStyle}
`;

const Footer = () => (
  <Wrapper>
    <Column>
      <Logo>DIGITO</Logo>
      <span>Digito AB, 2021, all rights reserved</span>
    </Column>
    <Column>
      <ColumnTitle>Länkar:</ColumnTitle>
      <StyledLink href="/om-oss">Om oss</StyledLink>
      <StyledLink href="/hallbarhet">Hållbarhet</StyledLink>
      <StyledLink href="/foretag">Företag</StyledLink>
      <StyledLink href="/anvandarvillkor">Användarvillkor</StyledLink>
      <StyledLink href="/personuppgiftspolicy">Personuppgiftspolicy</StyledLink>
    </Column>
    <Column>
      <ColumnTitle>Sociala medier:</ColumnTitle>
      <IconWrapper>
        <a
          href={config.DIGITO_LINKEDIN_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <LinkedInIcon />
        </a>
        <a
          href={config.DIGITO_INSTAGRAM_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <InstagramIcon />
        </a>
        <a
          href={config.DIGITO_FACEBOOK_URL}
          rel="noopener noreferrer"
          target="_blank"
        >
          <FacebookIcon />
        </a>
      </IconWrapper>
    </Column>
  </Wrapper>
);

export default Footer;
