const config = {
  API_BASE: 'https://api.digito.se',
  // API_BASE: 'http://localhost:3001',
  CLOUDINARY_CLOUD_NAME: 'dn8jcqfmh',
  HOST: 'https://digito.se',
  EMAIL: 'support@digito.se',
  CHECKOUT_URL: 'https://api.digito.se/api/checkout/create',
  // CHECKOUT_URL: 'http://localhost:3001/api/checkout/create',
  BILLING_URL: 'https://api.digito.se/api/checkout/billing',
  DIGITO_LINKEDIN_URL: 'https://www.linkedin.com/company/digito-group/',
  DIGITO_INSTAGRAM_URL: 'https://www.instagram.com/digito.se/',
  DIGITO_FACEBOOK_URL: 'https://www.facebook.com/Digito.se/'
};

export default config;
