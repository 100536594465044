import '../styles/global.css';

import React, { useEffect } from 'react';
import { ThemeProvider as EmThemeProvider } from '@emotion/react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { StylesProvider } from '@material-ui/styles';
import { Analytics } from '@vercel/analytics/react';
import { CloudinaryContext } from 'cloudinary-react';
import { AuthContextProvider } from 'contexts/AuthContext';
import { NotificationContextProvider } from 'contexts/NotificationContext';
import Layout from 'layout/Layout';
import Head from 'next/head';
import { useRouter } from 'next/router';
import config from '../config';
import muiTheme from '../styles/muiTheme';
import theme from '../styles/theme';
import * as gtag from '../utils/gtag';

const App = ({ Component, pageProps }) => {
  const router = useRouter();

  const layoutOptions = Component?.layoutOptions ?? {};

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles && jssStyles.parentNode)
      jssStyles.parentNode.removeChild(jssStyles);
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        ></meta>
      </Head>
      <StylesProvider injectFirst>
        <EmThemeProvider theme={theme}>
          <MuiThemeProvider theme={muiTheme}>
            <CloudinaryContext cloudName={config.CLOUDINARY_CLOUD_NAME}>
              <NotificationContextProvider>
                <AuthContextProvider>
                  <Layout {...layoutOptions}>
                    <>
                      <Component {...pageProps} />
                      <Analytics />
                    </>
                  </Layout>
                </AuthContextProvider>
              </NotificationContextProvider>
            </CloudinaryContext>
          </MuiThemeProvider>
        </EmThemeProvider>
      </StylesProvider>
    </>
  );
};

export default App;
