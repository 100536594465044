import axios from 'axios';
import { sentryAPICatch } from 'utils/sentryAPICatch';
import config from './config';

const createFullUser = async ({ slug, data }) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${config.API_BASE}/api/users/${slug}`,
      data
    });
    return response.data;
  } catch (error) {
    sentryAPICatch(error, `/users/${slug}`);
  }
};

const createUser = async ({ data }) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${config.API_BASE}/api/users`,
      data
    });
    return response.data;
  } catch (error) {
    sentryAPICatch(error, `/users`);
  }
};

const deleteUser = async ({ data }) => {
  try {
    const resp = await axios({
      method: 'DELETE',
      url: `${config.API_BASE}/api/users/${data.slug}`,
      data
    });
    return resp;
  } catch (error) {
    sentryAPICatch(error, `/users/${data.slug}`);
  }
};

const getAllUser = async ({ query = '' }) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${config.API_BASE}/api/users${query}`
    });
    return response.data;
  } catch (error) {
    sentryAPICatch(error, `/users${query}`);
  }
};

const getAllUserSlugs = async () => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${config.API_BASE}/api/users/slugs`
    });
    return response.data;
  } catch (error) {
    sentryAPICatch(error, `/users/slugs`);
  }
};

const getUserBySlug = async ({ slug }) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${config.API_BASE}/api/users/${slug}`
    });
    return response.data;
  } catch (error) {
    sentryAPICatch(error, `/users/${slug}`);
  }
};

const updateUser = async (data) => {
  try {
    const resp = await axios({
      method: 'PUT',
      url: `${config.API_BASE}/api/users/${data.slug}`,
      data
    });
    axios({
      method: 'GET',
      url: `/api/revalidate?secret=digito-secret&path=/${data.slug}`
    });
    return resp.data;
  } catch (e) {
    sentryAPICatch(e, `/users/${data.slug}`);
  }
};

const getGeocoding = async ({ address }) => {
  try {
    const resp = await axios({
      method: 'GET',
      url: `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=se,dk,no&access_token=${process.env.NEXT_PUBLIC_mapboxgl_access_token}`
    });
    return resp.data.features;
  } catch (error) {
    sentryAPICatch(
      error,
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json?country=se,dk,no`
    );
  }
};

const createCompany = async (data) => {
  const response = await axios({
    method: 'POST',
    url: `${config.API_BASE}/api/companies`,
    data
  });
  return response.data;
};

const getCompany = async (name) => {
  try {
    const response = await axios({
      method: 'GET',
      url: `${config.API_BASE}/api/companies/${name}`
    });
    return response.data;
  } catch (error) {
    sentryAPICatch(error, `/companies/${name}`);
  }
};

const getCompanyNames = async () => {
  try {
    const resp = await axios({
      method: 'GET',
      url: `${config.API_BASE}/api/companies/meta/names`
    });
    return resp.data;
  } catch (error) {
    sentryAPICatch(error, `/companies/meta/names`);
  }
};

const updateCompany = async (data) => {
  try {
    const response = await axios({
      method: 'PUT',
      url: `${config.API_BASE}/api/companies/${data.name}`,
      data
    });
    return response.data;
  } catch (error) {
    sentryAPICatch(error, `/companies/${data.name}`);
  }
};

const deleteCompany = async (name) => {
  const response = await axios({
    method: 'DELETE',
    url: `${config.API_BASE}/api/companies/${name}`
  });
  return response.data;
};

const getUserByFirebaseId = async (id) => {
  try {
    const resp = await axios({
      method: 'GET',
      url: `${config.API_BASE}/api/users/id/${id}`
    });
    return resp.data;
  } catch (error) {
    sentryAPICatch(error, `/users/id/${id}`);
  }
};

const userTracking = async (data) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: `${config.API_BASE}/api/tracking`,
      data
    });
    return resp.data;
  } catch (error) {
    sentryAPICatch(error, `api/tracking`);
  }
};

const createSubscription = async (data) => {
  try {
    const resp = await axios({
      method: 'POST',
      url: `${config.API_BASE}/api/checkout`,
      data
    });
    return resp.data;
  } catch (error) {
    sentryAPICatch(error, `api/tracking`);
  }
};

const updatePaymentMethod = async (data) => {
  try {
    await axios({
      method: 'PUT',
      url: `${config.API_BASE}/api/checkout`,
      data
    });
    return resp.data;
  } catch (error) {
    sentryAPICatch(error, `api/tracking`);
  }
};

const cancelPayment = async (data) => {
  try {
    const resp = await axios({
      method: 'DELETE',
      url: `${config.API_BASE}/api/checkout`,
      data
    });
    return resp.data;
  } catch (error) {
    sentryAPICatch(error, `api/tracking`);
  }
};

export {
  createFullUser,
  createUser,
  deleteUser,
  getAllUser,
  getAllUserSlugs,
  getUserBySlug,
  updateUser,
  getGeocoding,
  createCompany,
  getCompany,
  getCompanyNames,
  updateCompany,
  deleteCompany,
  getUserByFirebaseId,
  userTracking,
  createSubscription,
  updatePaymentMethod,
  cancelPayment
};
