import React from 'react';
import styled from '@emotion/styled';
import Link from '../Link';

const Span = styled.span`
  color: ${({ theme, black }) =>
    black ? theme.palette.BLACK : theme.palette.WHITE};
  cursor: pointer;
  font-family: 'Rajdhani', sans-serif;
  letter-spacing: 2.5px;
  font-weight: ${({ black }) => black && 'bold'};
  ${({ large }) => large && 'font-size: 30px'}
`;

const Logo = ({ black, large }) => {
  return (
    <Link href={'/'}>
      <Span black={black} large={large}>
        DIGITO
      </Span>
    </Link>
  );
};

export default Logo;
