import React, { createContext, useContext, useState } from 'react';
import Notification from 'components/Notification';

const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const [notification, setNotification] = useState();

  return (
    <NotificationContext.Provider value={{ notification, setNotification }}>
      {notification && (
        <Notification
          {...notification}
          onExited={() => {
            setNotification(undefined);
          }}
        />
      )}
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);

export default NotificationContext;
